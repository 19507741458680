import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import { getAllEmployee, EmployeeReport } from "../../../actions/employeeAction";
import { getSpecificUserEmployeeLeave } from "../../../actions/employeeLeaveActions";
import { createEmployeePaySlip } from "../../../actions/PaySlipAction";
import moment from "moment";
import Calendarlist from "./Calenderlist";

import { Layout, Breadcrumb, Row, Col, Select, Divider, DatePicker, Button, InputNumber } from "antd";
const { Content } = Layout;
const { Option } = Select;

const AddPayslip = ({ getAllEmployee, employeeList, EmployeeReport, createEmployeePaySlip, getSpecificUserEmployeeLeave }) => {
  const [updatelist, setUpdatelist] = useState(false);
  const [data, setData] = useState();
  const componentRef = useRef();
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const selectedEmployee = useRef(null)
  const DaysInMonth = useRef("")
  const Month = useRef(1)
  const Year = useRef(2023)
  const NoOfHolidays = useRef(0)
  const NoOfWokingDays = useRef(0)
  const Overtime = useRef(0)
  const Extraovertime = useRef(0)
  const holidayAllowance = useRef(0)
  const holidayattendance = useRef(0)
  const attendancebouns = useRef(0)

  // useEffect(() => {
  //   getAllEmployee();
  // }, []);

  useEffect(() => {
    if (selectedEmployee.current != null && DaysInMonth.current != "") {
      setLoading(true);
      EmployeeReport(selectedEmployee.current, Month.current, Year.current, DaysInMonth.current, NoOfHolidays.current, NoOfWokingDays.current, Overtime.current, Extraovertime.current, holidayAllowance.current, holidayattendance.current, attendancebouns.current).then(data => {
        // console.log(data);
        setData(data)
        setLoading(false);
      });
    }
  }, [reload]);

  const onFinish = (method, heldup) => {
    console.log(data.employee)
    console.log(JSON.stringify(data.employee))
    const formData = new FormData();
    formData.append("employee", data.employee.id);
    formData.append("salary", data.salary.id);
    formData.append("salaryMonth", Month.current);
    formData.append("salaryYear", Year.current);
    formData.append("publicHoliday", NoOfHolidays.current);
    formData.append("payment_method_info_1", method);
    formData.append("heldup", heldup);
    formData.append("amount_1", data.summaryData.totalsalary);
    formData.append("employeeData", JSON.stringify(data.employee));
    formData.append("attendanceData", JSON.stringify(data.attendance));
    formData.append("leaveData", JSON.stringify(data.leave));
    formData.append("salaryData", JSON.stringify(data.salary));
    formData.append("summaryData", JSON.stringify(data.summaryData));
    createEmployeePaySlip(formData).then(e => {
      alert("Paid")
    })

  }

  var days = function (month, year) {
    return new Date(year, month, 0).getDate();
  };

  const onMonthChange = (value, dateString) => {
    const myArr = dateString.split("-");
    let year = myArr[0];
    let mnth = myArr[1];
    Month.current = mnth
    Year.current = year
    DaysInMonth.current = days(mnth, year)
  };
  const onFilter = () => {
    if (selectedEmployee.current == null) {
      alert("Please select an Employee")
      return
    }
    if (DaysInMonth.current == "") {
      alert("Please select a month")
      return
    }
    setReload(!reload)
  }

  return (
    <>
      <Breadcrumb style={{ margin: "16px 0" }}>
        <Breadcrumb.Item>Employee Report</Breadcrumb.Item>
      </Breadcrumb>

      <div className="site-layout-background main-frame">
        <Row wrap={true}>
          <Col flex="auto">
            EPIN :
            <InputNumber
              placeholder="Employee PIN"
              style={{ width: "100%", minWidth: "140px" }}
              onChange={(value) => {
                selectedEmployee.current = value;
              }}
              min={0}
            />
          </Col>
          <Col flex="auto">
            Month :
            <DatePicker
              picker="month"
              style={{ width: "100%", minWidth: "140px" }}
              // defaultValue={moment()}
              onChange={onMonthChange}
              bordered={true}
            />
          </Col>
          <Col flex="auto">
            No of Holidays :
            <InputNumber
              placeholder="No of Holidays"
              style={{ width: "100%", minWidth: "40px" }}
              onChange={(value) => {
                NoOfHolidays.current = value;
              }}
              // defaultValue={0}
              min={0}
            />
          </Col>
          <Col flex="auto">
            .
            <Button style={{ width: "100%", minWidth: "140px" }} type="primary" onClick={onFilter}>
              Filter
            </Button>
          </Col>
        </Row>
        <Divider />
        {data ?
          (data.error == 0) ?
            <>
              <ReactToPrint
                trigger={() => <button>Print this out!</button>}
                content={() => componentRef.current}
              />
              <Divider />
              <div ref={componentRef} style={{ padding: "10px", border: "1px solid gray" }}>
                <Row >
                  <Col flex="auto"><h3>Famous Design LTD.</h3>
                    <p>245/1-F,West Agargoan, Sher-E-Bangla nagar,Dhaka-1207.</p>
                    <p>মাসের নাম ও বছর : {moment(Year.current + "-" + Month.current + "-1").format("MMMM,  YYYY")}</p>
                  </Col>
                  <Col flex="auto">
                    <table>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>কার্ড নং</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.employee.employee_pin}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>নাম</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.employee.name}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>পদবি</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.employee.user_roleName}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>বিভাগ</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.employee.employeeDepartment}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>যোগদান</td>
                        <td style={{ paddingLeft: "10px" }}>: {moment(data.employee.joining_date).format("DD-MM-YYYY")}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>বেতন বৃদ্ধি</td>
                        <td style={{ paddingLeft: "10px" }}></td>
                      </tr>

                    </table>
                  </Col>
                  <Col flex="auto">
                    <table>

                      <tr>
                        <td style={{ paddingRight: "10px" }}>মাসের দিন</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.attendance.totaldays}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>কাজের দিন</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.attendance.workingdays}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>সাপ্তাহিক ছুটি</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.attendance.Holidays}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>উপস্থিত</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.attendance.present}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>অনুপস্থিত</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.attendance.absent}</td>
                      </tr>


                    </table>
                  </Col>
                </Row>
                <Divider />
                <Row wrap={false}>
                  {/* <Col flex="auto">
                    <table>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>কার্ড নং</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.employee.employee_pin}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>নাম</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.employee.name}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>পদবি</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.employee.user_roleName}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>বিভাগ</td>
                        <td style={{ paddingLeft: "10px" }}>: {data.employee.employeeDepartment}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>যোগদান</td>
                        <td style={{ paddingLeft: "10px" }}>: {moment(data.employee.joining_date).format("DD-MM-YYYY")}</td>
                      </tr>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>বেতন বৃদ্ধি</td>
                        <td style={{ paddingLeft: "10px" }}></td>
                      </tr>

                    </table>
                  </Col> */}

                  {/* <Col flex="auto">
                    <table>
                      <tr>
                        <td style={{ paddingRight: "10px" }}>
                          ছুটির কারণ<br />
                          {data.leave.map(item => {
                            return <>{item.leaveType__Typename}<br /></>
                          })}
                        </td>
                        <td style={{ paddingLeft: "10px" }}>
                          <br />
                          {data.leave.map(item => {
                            return <>{item.total}<br /></>
                          })}
                        </td>
                      </tr>
                    </table>
                  </Col> */}
                  <Col flex="auto"><Calendarlist data={data} /></Col>
                </Row>
              </div>


            </>
            :
            <>
              <h1 style={{ marginTop: "20px", color: "red" }}>{data.message}</h1>
            </>

          :
          ""
        }

      </div >
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    employeeList: state.employee.employeelist,
  };
};

export default connect(mapStateToProps, { getAllEmployee, EmployeeReport, createEmployeePaySlip, getSpecificUserEmployeeLeave })(AddPayslip);
