import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllUserRole, getAllUserRoleFromZKtecho } from "../../../actions/userRoleAction";
import { Spin } from "antd";
import Rendertable from "./Rendertable";

const UserRoleList = ({
  getAllUserRole,
  getAllUserRoleFromZKtecho,
  RoleList,
  setReload,
  reload,
}) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getAllUserRoleFromZKtecho();
  }, []);

  useEffect(() => {
    setLoading(true)
    getAllUserRole().then(e => {
      setLoading(false)
    });
    // setReload(!reload);
  }, [reload]);

  // console.log(RoleList);

  return (
    <>
      <Spin spinning={loading}>
        <Rendertable
          List={RoleList}
          reload={reload}
          setReload={setReload}
        />
      </Spin>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    RoleList: state.userRole.userRolelist,
  };
};

export default connect(mapStateToProps, {
  getAllUserRole,
  getAllUserRoleFromZKtecho,
})(UserRoleList);
