import React, { useState } from "react";
import { connect } from "react-redux";
import UserRoleList from "./UserRoleList";
import CreateNewUserRole from "./CreateNewUserRole";
import UserRoleemplateDownload from "./UserRoleTemplateDownload";
import { DatePicker, Select, TreeSelect, Col, Row, Button, Spin } from "antd";

const Maincontent = ({ auth }) => {
  const [reload, setReload] = useState(true);
  const [data, setData] = useState([]);
  return (
    <>
      <Row wrap={false}>
        <Col flex="auto">
          <Button
            onClick={() => (window.location.href = "/import-role")}
            style={{ width: "100%", }}
          >
            User Role Data!
          </Button>
        </Col>
        <Col flex="auto">
          <UserRoleemplateDownload data={data} data1={data} />
        </Col>
      </Row>
      <div className="site-layout-background main-frame">
        {auth.permissions.includes("HRM.Designation_is_create")
          ? <CreateNewUserRole
            setReload={setReload}
            reload={reload}
          /> : ""}
        <UserRoleList reload={reload} setReload={setReload} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Maincontent);
